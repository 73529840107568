import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
/**
 * Shadow me to add your own content
 */


const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  },
  button: {

    display: `block`,
    mx: `auto`
  }
}
export default props => {
  const data = useStaticQuery(newsletterQuery2)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='Newsletter Subscription' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Subscribe'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
          </Box>
          <Divider />
        </Main>
      </Stack>
      <Stack>
        <Main> <NewsletterExpanded /> </Main>
      </Stack>
    </>
  )
}

const newsletterQuery2 = graphql`
  query newsletterQuery2 {
    photo: file(absolutePath: { regex: "/littlemanly.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
